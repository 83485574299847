import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PlanCommand } from 'src/app/commands/plan.command';
import { UserCommand } from 'src/app/commands/user.command';
import { PlanModel } from 'src/app/models/plan.model';
import { FirebaseService } from 'src/app/services/firebase/firebase.service';
import { ModalService } from 'src/app/services/utils/modal.service';

@Component({
  selector: 'app-plans',
  templateUrl: './plans.component.html',
  styleUrls: ['./plans.component.scss']
})
export class PlansComponent implements OnInit {
  plans: PlanModel[] = null;
  atual_plan: PlanModel = null;
  typePlan: string = 'asset';

  constructor(
    private firebaseService: FirebaseService,
    private modalService: ModalService,
    private userCommand: UserCommand,
    private planCommand: PlanCommand,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.firebaseService.viewScreen('plan');

    this.getData();
  }

  getData() {

    if (localStorage.getItem('atual_plan')) {
      this.atual_plan = JSON.parse(localStorage.getItem('atual_plan')) as PlanModel;
    }

    this.planCommand.get().then((result: any) => {
      this.plans = result.results;
    })
  }

  hasStockPlans() {
    return this.plans.some(item => item.is_stock);
  }

  filterPlan() {
    if(this.typePlan == 'asset')
      return this.plans.filter(item => !item.is_stock);
    return this.plans.filter(item => item.is_stock);
  }
}
