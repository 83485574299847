<div class="body">
    <section class="body-content">
        <div class="body-title">Leia com atenção</div>
        <span class="body-text color-over">
            No momento da transferência, é importante que a conta de negociação tenha o saldo suficiente para realização do valor do saque solicitado. 
            Caso a conta não tenha o saldo suficiente a solicitação será negada e uma nova deverá ser realizada.
        </span>
    </section>
    
    <section class="footer-buttons">
        <button class="button-primary" (click)="action(true)">Continuar</button>
        <button class="button-danger" (click)="action()">Cancelar</button>
    </section>
</div>