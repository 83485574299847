<app-auth-header page="history"></app-auth-header>

<div class="content" *ngIf="!loading">
    <div class="title">
        Solicitações de Saques (BETA)
    </div>
    <section class="row-content withdraw-content" *ngIf="account_operation">
        <div class="card withdraw" *ngIf="!avaliable && account_operation.status == 2">
            <div class="logo">
                <img [src]="account_operation.plan.image">
            </div>
            <span class="obs" >Não é possivel solicitar um saque no momento</span>
        </div>
        <div class="card withdraw" *ngIf="avaliable && account_operation.status == 2">
            <div class="logo">
                <img [src]="account_operation.plan.image">
            </div>
            <div class="balance">
                <span class="value">$ {{avaliable.account_balance| number:'1.2-2'}}</span>
                <span class="desc">Saldo da conta</span>
            </div>
            <div class="values">
                <div class="avaliable">
                    <span class="value">$ {{avaliable.withdraw_available | number:'1.2-2'}}</span>
                    <span class="desc">Disponível para saque</span>
                    <div class="line"></div>
                </div>
                <div class="remaining">
                    <span class="value">$ {{afterWithdraw() | number:'1.2-2'}}</span>
                    <span class="desc">Disponível após saque</span>
                    <div class="line"></div>
                </div>
            </div>
            <span class="obs" *ngIf="avaliable && avaliable.description"><span class="emph">*</span> {{avaliable.description}}</span>
            <div class="input input-space-min" *ngIf="avaliable && avaliable.withdraw_available > 0 && showForm">
                <input class="input-box" type="text" placeholder="Informe o valor para saque" [(ngModel)]="withdraw_value"
                    [class.input-box-error]="withdraw_error" (keyup)="maskMoney()">
                <span class="error-form" *ngIf="withdraw_error">
                    O valor para saque não pode ser menor que zero e nem ultrapassar o limite disponível.
                </span>
            </div>
            <div class="button-center" *ngIf="avaliable && avaliable.withdraw_available > 0 && showForm">
                <button class="button-primary" (click)="confirmRequest()">Solicitar</button>
            </div>
        </div>
        <div class="history" *ngIf="listWithDraw.length == 0">
            <span>Nenhuma solicitação ainda realizada</span>
        </div>
        <div class="card history" *ngIf="listWithDraw.length != 0">
            <table>
                <thead>
                    <tr>
                        <th>Atendido em</th>
                        <th>Valor solicitado</th>
                        <th>Status</th>
                        <th>Tipo de transferência</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of listWithDraw">
                        <td>{{item.completion_date| date: 'dd/MM/yyyy'}}</td>
                        <td>{{item.value | currency: '$'}}</td>
                        <td>
                            <span class="color-warning" *ngIf="item.status == 1">Aguardando aprovação</span>
                            <span class="color-info" *ngIf="item.status == 2">Aprovado</span>
                            <span class="color-danger" *ngIf="item.status == 3">Negado</span>
                            <span class="color-primary-medium" *ngIf="item.status == 4">Finalizado</span>
                        </td>
                        <td>
                            <span  *ngIf="item.type_payment == 1">PIX</span>
                            <span  *ngIf="item.type_payment == 2">TED</span>
                        </td>
                        <td></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </section>
</div>