import { Component, Input, OnInit } from '@angular/core';
import * as moment from 'moment';
import { UserModel } from 'src/app/models/user.model';
import { FirebaseMessagingService } from 'src/app/services/firebase/firebase-messaging.service';
import { EventsService } from 'src/app/services/utils/events.service';

@Component({
  selector: 'app-warning-dashboard',
  templateUrl: './warning-dashboard.component.html',
  styleUrls: ['./warning-dashboard.component.scss']
})
export class WarningDashboardComponent implements OnInit {

  @Input() types: number[];

  warnings_selected: any[] = [];
  
  warnings: any[] = [
    {
      type: 1,
      title: 'Aviso importante! Sua conta de negociação ainda não está ativada!',
      description: 'Olá [name], a sua conta ainda não foi ativada, antes de dar continuidade é de extrema importância realizar a ativação da conta, <a href="/ativar-conta" class="text-click">clique aqui</a> e siga todas as instruções para realizar a ativação. Qualquer dúvida, entre em contato com o nosso time de suporte através do e-mail suporte@insiderinveste.com',
      style: 'warning'
    },
    {
      type: 2,
      title: 'Aviso importante! O Insider Investe que você tem instalado precisa de uma nova atualização!',
      description: 'Olá [name], temos uma atualização do Insider Investe Real Time, e é de extrema importância que você realize a atualização, <a href="/atualizar-real-time" class="text-click">clique aqui</a> para baixar e instalar a versão mais recente.',
      style: 'warning'
    },
    {
      type: 3,
      title: 'Ops! Nenhuma conta de negociação foi encontrada',
      description: 'Olá [name], infelizmente nenhuma conta foi direcionada para você, mas não se preocupe, entre em contato com o nosso suporte através do e-mail suporte@insiderinveste.com.br que nós vamos resolver para você o mais breve possível.',
      style: 'neutro'
    },
    {
      type: 5,
      title: 'Infelizmente não foi dessa vez [name] :(',
      description: 'Olá [name], infelizmente você foi reprovado na nossa mesa proprietária. Mas não se preocupe pois é fácil solicitar um reset, basta clicar no botão abaixo e seguir as instruções que irão aparecer na tela.',
      style: 'danger'
    },
    {
      type: 6,
      title: 'Olá [name], sua aprovação está em análise',
      description: '[name] vimos que você alcançou a meta, e agora você está muito próximo da sua aprovação. Nossos especialista estão analisando se todos os requisitos foram cumprimos, em breve iremos entrar em contato com você com o resultado.',
      style: 'success'
    },
    {
      type: 7,
      title: 'Atenção, seu plano está proximo de expirar',
      description: 'Olá [name], em poucos dias seu plano irá expirar, para garantir que seu progresso não seja perdido renove seu plano.',
      style: 'warning',
      canClose: true,
    },
    {
      type: 8,
      title: 'Ops! As suas notificações estão bloqueadas!',
      description: 'Olá [name], para que você tenha uma experiência ainda melhor com a nossa plataforma, pedimos que você habilite as notificações para o nosso site no seu navegador, <a href="/ativar-notificacoes" class="text-click">clique aqui</a> e siga os passos para permitir o envio de noticações.',
      style: 'warning',
    },
    {
      type: 9,
      title: 'Ops! As suas notificações não foi habilitada',
      description: 'Olá [name], para que você tenha uma experiência ainda melhor com a nossa plataforma, pedimos que você habilite as notificações para o nosso site no seu navegador, clique no botão abaixo e em seguida clique em permitir no pop-up que irá aparecer no seu navegador.',
      style: 'warning',
    },
    {
      type: 10,
      title: 'Olá [name], o Insider Investe precisa da sua atenção!',
      description: 'Identificamos que o Insider Investe Real Time foi instalado, mas para o funcionamento correto da nossa ferramenta, precisamos que o Algotrading esteja habilitado. <a href="/habilitar-algotrading" class="text-click">Clique aqui</a> e veja como habilitar o Augotrading',
      style: 'warning',
    },
  ]

  constructor(private eventsService: EventsService,
    private firebaseMessagingService: FirebaseMessagingService, ) {
  }

  ngOnInit(): void {
    this.renderWarning();

    this.eventsService.get_event().subscribe((event: string) => {
      if (event == 'renderWarning') this.renderWarning();
    })
  }

  renderWarning() {
    this.warnings_selected = []
    let user: UserModel = JSON.parse(localStorage.getItem('user')) as UserModel;
    let listExhibitWarning: Array<any> = JSON.parse(localStorage.getItem('exhibitWarnings')) ?? [];

    for (let index = 0; index < this.types.length; index++) {
      const element = this.types[index];
      let warning: any = this.warnings.filter(x => x.type == this.types[index])[0]
      warning.title = warning.title.replace('[name]', user.name.split(' ')[0])
      warning.description = warning.description.replace('[name]', user.name.split(' ')[0])
      if(!Object.keys(warning).includes('canClose')){
        warning.canClose = false
      }
      if(warning.canClose && listExhibitWarning.find(item => item.type == warning.type && item.date == moment(new Date()).format('YYYY-MM-DD')))
        continue;
      this.warnings_selected.push(warning);
    }
  }

  close(type: number){
    let lastExhibitWarning = {type: type, date: moment(new Date()).format('YYYY-MM-DD')},
    listExhibitWarning: Array<any> = JSON.parse(localStorage.getItem('exhibitWarnings')) ?? [];
    
    if(listExhibitWarning.length){
      listExhibitWarning.map(item => {
        if(item.type == type){
          item = lastExhibitWarning;
        }
      })
    }else{
      listExhibitWarning.push(lastExhibitWarning);
    }

    localStorage.setItem('exhibitWarnings', JSON.stringify(listExhibitWarning));
    this.warnings_selected = this.warnings_selected.filter(item => item.type != type);
  }

  requestPermissionNotification() {
    this.firebaseMessagingService.requestPermission().subscribe(
      {
        next: (token) => {
          window.location.reload()
         },
        error: (error) => { }
      }
    );
  }
}
