import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DashboardCommand } from 'src/app/commands/dashboard.command';
import { PerformanceCommand } from 'src/app/commands/performance.command';
import { UserCommand } from 'src/app/commands/user.command';
import { WithdrawCommand } from 'src/app/commands/withdraw.command';
import { AccountInfoComponent } from 'src/app/components/account-info/account-info.component';
import { LogAccountComponent } from 'src/app/components/log-account/log-account.component';
import { AccountOperationModel } from 'src/app/models/account-operation.model';
import { DashboardModel } from 'src/app/models/dashboard.model';
import { PerformanceModel } from 'src/app/models/performance.model';
import { PlanExpiration } from 'src/app/models/plan_expiration.model';
import { UserModel } from 'src/app/models/user.model';
import { WithdrawModel } from 'src/app/models/withdraw.model';
import { FormatDataChartService } from 'src/app/services/utils/format-data-chart/format-data-chart.service';
import { ModalService } from 'src/app/services/utils/modal.service';
import { PagesContentService } from 'src/app/services/utils/pages-content.service';
import { RenderChartLineService } from 'src/app/services/utils/render-charts/render-chart-line.service';

@Component({
  selector: 'app-performance',
  templateUrl: './performance.component.html',
  styleUrls: ['./performance.component.scss']
})
export class PerformanceComponent implements OnInit {
  user: UserModel;
  account_not_found: boolean = false;
  account_operation: AccountOperationModel;
  dashboard: DashboardModel;
  performance: PerformanceModel;
  profit_today: number = 0;
  consistency: { average: number, percent: number, color: string, best_value: number };
  valueProfitLoss: number = 0;
  listWithDraw: WithdrawModel[] = [];
  allowWithdraw: boolean = false;

  alert_plan_renovation: any;

  constructor(
    private activatedRoute: ActivatedRoute,
    private performanceCommand: PerformanceCommand,
    private dashboardCommand: DashboardCommand,
    private formatDataChartService: FormatDataChartService,
    private renderLineGraph: RenderChartLineService,
    private router: Router,
    private modalService: ModalService,
    private withdrawCommand: WithdrawCommand,
    private contentPageService: PagesContentService,
    private userCommand: UserCommand,
  ) { }

  ngOnInit(): void {
    setTimeout(() => {
      let page_block = this.contentPageService.getBlock('extrato');

      if(page_block)
        this.allowWithdraw = false;
      else
        this.allowWithdraw = true;
    },1000);
    this.getData();
  }

  goRules() {
    this.router.navigate(['/regras-objetivos'], {state: {account: this.account_operation.account}});
  }

  getData() {
    //recupera os parâmetros da requisição
    this.activatedRoute.params.subscribe(async (params: any) => {
      if (params.id) {
        Promise.all([
          this.dashboardCommand.get(params.id),
          this.performanceCommand.get(params.id),
          this.userCommand.get(),
        ]).then(async (data: any) => {
          if (data[0]) {
            this.dashboard = data[0];
            this.performance = data[1];
            this.user = data[2];
            
            this.setConsistency(this.clone(this.dashboard));
            this.calcValueProfitLoss();

            this.account_operation = this.dashboard.account_info;
            await this.withdrawCommand.get(this.account_operation.id).then((result: any) => {
              if(result && result.results)
                this.listWithDraw = result.results.filter((item: any) => item.status == 2);
            });
            this.calcDaysPlanExpiration();

            setTimeout(() => {
              this.render();
            }, 500);

          } else {
            this.account_not_found = true
          }
        });
      } else {
        this.account_not_found = true
      }
    });
  }

  calcDaysPlanExpiration() {
    if(this.account_operation.lifetime)
      return;
      
    if(!this.account_operation.end_date_plan)
      return;

    const dataInicial: Date = new Date();
    const dataFinal: Date = new Date(this.account_operation.end_date_plan+'T00:00');
    
    // Calcule a diferença em milissegundos
    const diferenceTime: number = dataFinal.getTime() - dataInicial.getTime();

    // Converta a diferença para dias e adicione 1 para incluir o dia atual
    const diferenceDays: number = Math.ceil(diferenceTime / (1000 * 60 * 60 * 24)) + 1;

    if (diferenceDays <= 5 && diferenceDays > 0) {
      if (this.account_operation.account_balance >= this.account_operation.plan.value_account) {
        this.alert_plan_renovation = PlanExpiration.positive[diferenceDays - 1]
        this.alert_plan_renovation.description = this.alert_plan_renovation.description.replace('[name]',this.dashboard.user.name.split(' ')[0])
      } else {
        this.alert_plan_renovation = PlanExpiration.negative[diferenceDays - 1]
        this.alert_plan_renovation.description = this.alert_plan_renovation.description.replace('[name]',this.dashboard.user.name.split(' ')[0])
      }
    }
  }

  getAllowableLossDay() {
    let total: number = 0;

    for (let index = 0; index < this.dashboard.day_operations.length; index++) {
      const element = this.dashboard.day_operations[index];

      total += Number(element.value)
    }

    this.profit_today = total;

    const tota_loss_day: number = Number(this.account_operation.plan.limit_loss_day) * -1;
    return (tota_loss_day - total) <= 0 ? Math.abs(tota_loss_day - total): 0;
  }

  getAllowableLossAccount() {
    const drawdown = Number(this.account_operation.trailing_drawdown), balance = Number(this.account_operation.account_balance)
    return (balance - drawdown) > 0? balance - drawdown: 0;
  }

  setConsistency(dashboard: DashboardModel) {
    this.consistency = { average: 0, percent: 0, color: "primary", best_value: 0 };

    if (dashboard) {
      let average = 0, percent = dashboard.consistence, best_value = 0;
      this.consistency = { average, percent, color: percent >= 80 ? "color-primary-medium" : percent >= 50 ? "color-warning" : "color-danger", best_value: best_value }
    }
  }

  render() {
    if (!this.dashboard)
      return;

    let ops_evolution = this.formatDataChartService.formatEvolution(this.dashboard.operations, Number(this.dashboard.account_info.plan.value_account));
    let minValue = this.formatDataChartService.limitLoss(ops_evolution, this.dashboard.account_info.plan.trailing_drawdown, Number(this.dashboard.account_info.trailing_drawdown), 'Limite mínimo'),
      maxValue = this.formatDataChartService.consGraph((Number(this.dashboard.account_info.plan.profit_target) + Number(this.dashboard.account_info.plan.value_account)), this.dashboard.operations.length, 'Meta de aprovação');

    let dates: any = ops_evolution.map(item => item[0]);
    dates[0] = '';
    
    if(this.account_operation.type == 2)
      if(this.allowWithdraw)
        maxValue = this.formatDataChartService.limitPix(ops_evolution, Number(this.account_operation.withdrawal_limit), this.listWithDraw);
      else
        maxValue = null;

    if (ops_evolution.length) this.renderLineGraph.renderSimple('graph-evolution', ops_evolution, 295, maxValue, minValue, false, false, dates);
  }

  calcValueProfitLoss() {
    const media_operacoes_vencedoras = this.performance.media_operacoes_vencedoras == null || this.performance.media_operacoes_vencedoras == 0 ? 1 : this.performance.media_operacoes_vencedoras;
    const media_operacoes_perdedoras = this.performance.media_operacoes_perdedoras == null || this.performance.media_operacoes_perdedoras == 0 ? 1 : this.performance.media_operacoes_perdedoras;

    if (media_operacoes_vencedoras >= (media_operacoes_perdedoras * -1))
      this.valueProfitLoss = media_operacoes_vencedoras / (media_operacoes_perdedoras * -1);
    else
      this.valueProfitLoss = media_operacoes_perdedoras / media_operacoes_vencedoras;
  }

  openSalesLink() {
    window.open(this.account_operation.plan.sales_link, '_blank')
  }

  viewCredentials(account: AccountOperationModel) {
    this.modalService.setContent(account);
    this.modalService.openModal(AccountInfoComponent, '800px', '400px')
  }

  viewLog(account: AccountOperationModel) {
    this.modalService.setContent(account.logs);
    this.modalService.openModal(LogAccountComponent, '800px', '400px')
  }

  clone(data: any) {
    return JSON.parse(JSON.stringify(data));
  }

  getGrossBalance(liq: any, inv: number) {
    return Number(liq)+inv;
  }

  requestWithdraw() {
    this.router.navigate(['/extrato'], {state: {account: this.account_operation.id}});
  }
}
