// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  url: "https://api-front-insider-investe.azurewebsites.net/api/v1", //PRODUÇÃO
  //url: "https://api-homolog.insiderinveste.com.br/api/v1", //HOMOLOGAÇÃO
  firebaseConfig: {
    apiKey: "AIzaSyD3LOpMTHsbbhqlNM3SWj11c8KP_RUdotA",
    authDomain: "insider-investe.firebaseapp.com",
    projectId: "insider-investe",
    storageBucket: "insider-investe.appspot.com",
    messagingSenderId: "543708047914",
    appId: "1:543708047914:web:a715f0b92d49aee5a9f153",
    measurementId: "G-YB5HN3M0TF"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
