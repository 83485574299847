import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { PaymentCommand } from 'src/app/commands/payment.command';
import { PlanCommand } from 'src/app/commands/plan.command';
import { AlertService } from 'src/app/components/alert/alert.service';
import { ModalPayErrorComponent } from 'src/app/components/modal-pay-error/modal-pay-error.component';
import { SidenavService } from 'src/app/components/sidenav/sidenav.service';
import { AddressModel } from 'src/app/models/address.model';
import { PayModel, PixModel } from 'src/app/models/pay.model';
import { PaymentModel } from 'src/app/models/payment.model';
import { PlanModel } from 'src/app/models/plan.model';
import { FirebaseService } from 'src/app/services/firebase/firebase.service';
import { InputFormsService } from 'src/app/services/utils/input-forms.service';
import { ModalService } from 'src/app/services/utils/modal.service';
import { PagesContentService } from 'src/app/services/utils/pages-content.service';
import { TermsComponent } from '../terms/terms.component';
import { TermUseCommand } from 'src/app/commands/term-use.command';
import { TermUseModel } from 'src/app/models/term-use.model';
import { CouponModel } from 'src/app/models/coupon.model';
import { CouponCommand } from 'src/app/commands/coupon.command';
import { OneOffChargeCommand } from 'src/app/commands/one-off-charge.command';
import { OneOffChargeModel } from 'src/app/models/one-off-charge.model';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss']
})
export class PaymentComponent {
  loading: boolean = false;

  pay_method: string = 'CARTAO';
  formValidation: UntypedFormGroup;
  formCoupon: UntypedFormGroup;
  submitted: boolean = false;
  applied: boolean = false;
  coupon: CouponModel;
  charge: OneOffChargeModel;

  platform_selected: string = 'MT5';
  metaTraderValue: number = 0;
  blackArrowValue: number = 120;
  
  years: string[] = Array(21).fill(0).map((x,i)=>(new Date().getFullYear()+i).toString());
  plan: PlanModel;
  termUse: TermUseModel;

  payment: PaymentModel;
  nav: any;

  constructor(
    private activatedRoute: ActivatedRoute,
    public formBuilder: UntypedFormBuilder,
    public inputFormService: InputFormsService,
    private firebaseService: FirebaseService,
    private contentPageService: PagesContentService,
    private alertComponent: AlertService,
    private router: Router,
    private sidenavService: SidenavService,
    private planCommand: PlanCommand,
    private paymentCommand: PaymentCommand,
    private modalService: ModalService,
    private termUseCommand: TermUseCommand,
    private oneOffChargeCommand: OneOffChargeCommand,
    private couponCommand: CouponCommand
  ) {
    this.nav = this.router.getCurrentNavigation();
    this.payment = this.nav.extras.state? this.nav.extras.state.payment as PaymentModel: null;

    const queryParams = this.router.parseUrl(this.router.url).queryParams;
    if(queryParams.platform)
      this.changePlatform(queryParams.platform);
  }

  ngOnInit(): void {
    this.firebaseService.viewScreen('pagamento');
    this.sidenavService.enableProgressBar();
    this.sidenavService.getProgressBar().subscribe(
      value => {
        this.loading = value;
      }
    );
    this.formValidation = this.formBuilder.group({
      name: ['', [Validators.required]],
      email: ['', [Validators.required]],
      email_confirm: ['', [Validators.required]],
      document: ['', [Validators.required]],
      phone: ['', [Validators.required]],
      payment_type: ['CARTAO', [Validators.required]],
      card_number: [null, [Validators.required]],
      card_name: [null, [Validators.required]],
      card_month: [null, [Validators.required]],
      card_year: [null, [Validators.required]],
      card_cvv: [null, [Validators.required]],
      card_zipcode: [null, [Validators.required]],
      card_address: [null, [Validators.required]],
      card_city: [null, [Validators.required]],
      card_state: [null, [Validators.required]],
      card_complement: [null, []],
      card_district: [null, [Validators.required]],
      card_address_number: [null, [Validators.required]],
      plan_id: [null, [Validators.required]],
      accept_term: [false, [Validators.required, Validators.requiredTrue]],
      save_card: [false, []],
      installment_count: [1, []],
      ip: ['172.0.0.1', [Validators.required]],
    });

    this.formCoupon = this.formBuilder.group({
      coupon: ['', [Validators.required]],
    });

    this.activatedRoute.params.subscribe((params: any) => {
      const charge_id = this.activatedRoute.snapshot.queryParamMap.get('charge_id');

      Promise.all([
        this.planCommand.getPublic(params.id),
        this.termUseCommand.get(),  
        this.oneOffChargeCommand.get(charge_id)
      ]).then(([result, term, oneOffCharge]: [PlanModel, TermUseModel, OneOffChargeModel]) => {
        if (result) {
          if (!oneOffCharge && parseInt(charge_id) > 0) {
            this.router.navigate(['/erro-pagamento'], {state: {plan: this.plan, type: 'charge-not-found', payment: null}});
          }

          this.charge = oneOffCharge;
          this.plan = result;
          this.blackArrowValue = this.plan.value_black_arrow;
          this.formValidation.patchValue({plan_id: this.plan.id});
          if(this.payment)
            this.formValidation.patchValue(this.payment);
        } else {
          this.router.navigate(['/erro-pagamento'], {state: {plan: this.plan, type: 'plan-not-found', payment: null}});
        }
        if(term)
          this.termUse = term;
      });
    });

    setTimeout(() => {
      let page_block = this.contentPageService.getBlock('pagamento');
    
      if(page_block){
        this.alertComponent.presentAlert("warning", "Conteúdo indisponível", page_block.message);
        this.router.navigate(['home']);
      }
    },1000);
  }

  get errorCtr() {
    return this.formValidation.controls;
  }

  get errorCtrCoupon() {
    return this.formCoupon.controls;
  }

  focus(type: string, element: string) {
    if (type == 'focus') {
      this.inputFormService.setInputFocus(document, element, this.formValidation, this.submitted);
    } else if (type == 'unfocus') {
      this.inputFormService.unCheckFocus(document, element);
    }
  }

  updateMask(event, field: string) {
    this.inputFormService.updateMask(this.formValidation, field, event);
  }

  toUpper(){
    if(this.formCoupon.valid)
      this.formCoupon.patchValue({coupon: String(this.formCoupon.value.coupon).toUpperCase()});
  }

  async submit() {
    const queryParams = this.router.parseUrl(this.router.url).queryParams;
    this.firebaseService.eventClick('buy-plan', 'pagamento');
    this.submitted = true;

    if (this.formValidation.valid) {
      let payment: PaymentModel = {
        name: this.formValidation.value.name,
        email: this.formValidation.value.email,
        email_confirm: this.formValidation.value.email_confirm,
        document: this.formValidation.value.document,
        phone: this.formValidation.value.phone,
        payment_type: this.formValidation.value.payment_type,
        card_number: this.formValidation.value.card_number,
        card_name: this.formValidation.value.card_name,
        card_month: this.formValidation.value.card_month,
        card_year: this.formValidation.value.card_year,
        card_cvv: this.formValidation.value.card_cvv,
        card_zipcode: this.formValidation.value.card_zipcode,
        card_address: this.makeAddress(),
        card_address_number: this.formValidation.value.card_address_number,
        plan_id: this.formValidation.value.plan_id,
        ip: this.formValidation.value.ip,
        platform: this.platform_selected,
        accept_term: this.formValidation.value.accept_term,
        save_card: this.formValidation.value.save_card,
        coupon: this.coupon? this.coupon.name: null,
        term_id: this.termUse? this.termUse.id: null,
        charge_id: this.charge? this.charge.id: null,
        installment_count: this.formValidation.value.installment_count,
      };
      this.plan.value_plan = this.getFinalValue();
      
      this.paymentCommand.pay(payment, JSON.stringify(queryParams)).then((result: PayModel) => {
        if(!result.valid){
          this.router.navigate(['/erro-pagamento'], {state: {plan: this.plan, type: 'error', payment: payment, coupon: this.coupon, message_error: result.message}, queryParams: queryParams});
          return;
        }
        
        if(this.pay_method == 'CARTAO'){
          if(result.credit_card_status == 'CONFIRMED'){
            this.router.navigate(['/pagamento-confirmado'], {state: {plan: this.plan, type: 'success', payment: payment, coupon: this.coupon, charge: this.charge}, queryParams: queryParams});
            return;
          }
          if(result.credit_card_status == null){
            this.modalService.setContent(result.credit_card_error);
            this.modalService.openModal(ModalPayErrorComponent, "600px");
            return;
          }
          this.router.navigate(['/erro-pagamento'], {state: {plan: this.plan, type: 'error', payment: payment, coupon: this.coupon, message_error: result.message}, queryParams: queryParams});
          return;
        }
        
        if(this.pay_method == 'PIX'){
          if(!result.pix)
            this.router.navigate(['/erro-pagamento'], {state: {plan: this.plan, type: 'error', payment: payment, coupon: this.coupon, message_error: result.message}, queryParams: queryParams});
          else
            this.router.navigate([`/cobranca/pix`], {state: {plan: this.plan, pay_pix: result.pix, payment: payment, coupon: this.coupon, charge: this.charge}, queryParams: queryParams});
          return;
        }
        if(this.pay_method == 'BOLETO'){
          if(!result.ticket)
            this.router.navigate(['/erro-pagamento'], {state: {plan: this.plan, type: 'error', payment: payment, coupon: this.coupon, message_error: result.message}, queryParams: queryParams});
          else
            this.router.navigate([`/cobranca/boleto`], {state: {plan: this.plan, boleto: result.ticket, payment: payment, coupon: this.coupon, charge: this.charge}, queryParams: queryParams});
          return;
        }
      });
    }
  }

  changePayment(type: string){
    this.pay_method = type;
    this.formValidation.patchValue({payment_type: this.pay_method});
    const keys = ['card_number', 'card_name', 'card_month', 'card_year', 'card_cvv', 'card_zipcode', 'card_address', 'card_address_number', 'card_city', 'card_state', 'card_district'];
    if(type == 'CARTAO'){
      keys.forEach(key => {
        this.formValidation.controls[key].setValidators([Validators.required]);
        this.formValidation.controls[key].updateValueAndValidity();
      });
    }else{
      keys.forEach(key => {
        this.formValidation.controls[key].setValidators([]);
        this.formValidation.controls[key].updateValueAndValidity();
      });
    }
  }

  changePlatform(type: string) {
    this.platform_selected = type;
  }

  getAddress(zip_code: string) {
    this.paymentCommand.viaCep(zip_code.replace('-', '')).then((result: AddressModel) => {
      if(result){
        this.formValidation.patchValue({
          card_address: result.logradouro,
          card_city: result.localidade,
          card_state: result.uf,
          card_district: result.bairro,
        });
      }
    });
  }

  makeAddress() {
    if(this.formValidation.value.payment_type != 'CARTAO')
      return null;
    return `${this.formValidation.value.card_address}, ${this.formValidation.value.card_complement?? ''}, ${this.formValidation.value.card_district}, ${this.formValidation.value.card_address_number}, ${this.formValidation.value.card_city} - ${this.formValidation.value.card_state}`
  }

  getFinalValue() {
    let value: number = this.charge ? this.charge.value : this.plan.value_plan;
    
    if(this.coupon) {
      if(this.coupon.type_discount == 1)
        value *= (1 - (this.coupon.value_discount/100));
      else
        value -= this.coupon.value_discount;
    }
    if(this.platform_selected == 'BA')
      return value+Number(this.blackArrowValue);
    return value+Number(this.metaTraderValue);
  }

  openModal() {
    this.modalService.setContent(this.termUse);
    this.modalService.openModal(TermsComponent, '800px', '90vh');
  }

  applyCoupon() {
    this.applied = true;
    if(this.formCoupon.valid && this.errorCtr.email.valid)
      this.couponCommand.post(this.formCoupon.value.coupon, this.formValidation.value.email, this.plan.id).then((result: CouponModel) => {
        if(result){
          this.coupon = result;
          this.coupon.name = this.formCoupon.value.coupon;
        }
      });
  }

  removeCoupon() {
    this.coupon = null;
  }

  rangeList(value: number = 1): Array<number> {
    if(!this.charge && this.plan.installment_count)  value = this.plan.installment_count;
    if(this.charge && this.charge.installment_count)  value = this.charge.installment_count;
    return Array(value).fill(0).map((x, i) => i+1);
  }
}
