<div class="card">
    <div class="cnt">
        <table>
            <thead>
                <tr>
                    <th>Ativo</th>
                    <th>Descrição</th>
                    <th *ngIf="showContract">
                        Qtd. contratos
                        <i class="fa-regular fa-circle-question" matTooltip="Representa a quantidade máxima de contratos, lotes abaixo do exibido também podem ser operados"></i>
                    </th>
                    <th>Abertura <br><div class="locale">(Horário de Brasília)</div></th>
                    <th>Fechamento <br><div class="locale">(Horário de Brasília)</div></th>
                    <th>Dias da semana</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of assets[page]">
                    <td>{{item.asset}}</td>
                    <td>{{item.description}}</td>
                    <td *ngIf="showContract">{{item.contracts}}</td>
                    <td>{{timeFormat(item.hour_opening)}}</td>
                    <td>{{timeFormat(item.hour_closed)}}</td>
                    <td>{{item.note}}</td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="paginator">
        <button class="button-primary" [disabled]="page<=0" (click)="alterPage(-1)"><i class="fa-solid fa-chevron-left"></i></button>
        <button class="button-primary" [disabled]="page>=(assets.length-1)" (click)="alterPage(1)"><i class="fa-solid fa-chevron-right"></i></button>
    </div>
</div>