import { Injectable } from "@angular/core";
import * as moment from "moment";
import { OperationModel, WeekOperationModel } from "src/app/models/dashboard.model";
import { WithdrawModel } from "src/app/models/withdraw.model";

@Injectable({
    providedIn: 'root'
})

export class FormatDataChartService {
    month: string[] = ['', 'jan', 'fev', 'mar', 'abr', 'mai', 'jun', 'jul', 'ago', 'set', 'out', 'nov', 'dez'];

    formatEvolution(operations: OperationModel[], valueAccount: number){
        if(operations){
            let sum = valueAccount;
            let result: any[] = [['Saldo inicial', sum]]
            result = result.concat(operations.map(item => [item.operation_date? moment(item.operation_date).format("DD/MM/YYYY"):(`${this.month[item.month]}/${item.year}`), sum += Number(item.value)]));

            return result;
        }
        return [];
    }

    formatDay(operations: OperationModel[]){
        if(operations){
            let sum = 0,
            ops: any = operations.map(item => [item.asset, sum += Number(item.value)]);
            return [['', 0]].concat(ops);
        }
        return [];
    }

    formatWeek(operations: WeekOperationModel[]){
        if(operations){
            return operations.map(item => [moment(item.date).format("DD/MM/YYYY"), Number(item.total)]);
        }
        return [];
    }

    consGraph(value: number, len: number, description: string){
        let data = [];
        for(let i=0; i< len + 1;i++){
            data.push([description, Number(value)]);
        }
        return data;
    }

    limitLoss(ops_evolution: Array<Array<any>>, trailing_drawdown: any, account_trailing_drawdown: any, description: string) {
        let data = [];
        let max = 0;
        ops_evolution.forEach((item) => {
            let value = Number(item[1]), final_value = 0;
            if(value > max)
                max = value;
            
            final_value = max-Number(trailing_drawdown);

            if(final_value > account_trailing_drawdown)
                final_value = account_trailing_drawdown;
            
            data.push([description, final_value]);
        });
        return data;
    }

    limitPix(ops_evolution: Array<Array<any>>, limit: number, listWithdraw: WithdrawModel[]) {
        let data = [], indexWithdraw = 0;
        ops_evolution.reverse();
        ops_evolution.forEach((item) => {
            let date = this.transformDate(item[0]);
            data.push(["Limite pix", limit]);
            if( indexWithdraw < listWithdraw.length && date < new Date(listWithdraw[indexWithdraw].completion_date)){
                limit -= listWithdraw[indexWithdraw].value;
                indexWithdraw++;
            }
        });
        
        ops_evolution.reverse();
        return data.reverse();
    }

    transformDate(date: string) {
        if(!date.includes('/'))
            return new Date(0);
        const [day, month, year] = date.split('/');
        return new Date(`${year}-${month}-${day}T00:00`);
    }
}