<div class="side-content">
    <div class="close-button" (click)="closeMenu()">
        <i class="fa-solid fa-xmark"></i>
    </div>
    <section class="items-menu color-over">
        <div class="item" [class.color-primary-medium]="itemSelected == 'home'" [routerLink]="'/home'"
            (click)="openPage('home')">
            <div class="menu-icon"> <i class="fa-solid fa-house" [class.color-primary-medium]="itemSelected == 'home'" ></i> </div>
            <span>Início</span>
        </div>
        <div class="item" [class.color-primary-medium]="itemSelected == 'ativos-permitidos'" (click)="openPage('ativos-permitidos')"
            *ngIf="pagesContentService.isVisible('ativos-permitidos')">
            <div class="menu-icon"><img src="assets/icons/graph_green.svg" [class.active]="itemSelected == 'ativos-permitidos'"> </div>
            <span>Ativos Permitidos</span>
        </div>
        <div class="item" [class.color-primary-medium]="itemSelected == 'regras-objetivos'" (click)="openPage('regras-objetivos')"
            *ngIf="pagesContentService.isVisible('regras-objetivos')">
            <div class="menu-icon"><img src="assets/icons/rules_green.svg" [class.active]="itemSelected == 'regras-objetivos'"> </div>
            <span>Regras e Objetivos</span>
        </div>
        <div class="item" [class.color-primary-medium]="itemSelected == 'minha-conta'" (click)="openPage('minha-conta')"
            *ngIf="pagesContentService.isVisible('minha-conta')">
            <div class="menu-icon"> <i class="fa-solid fa-user" [class.color-primary-medium]="itemSelected == 'minha-conta'"></i> </div>
            <span>Minha conta</span>
        </div>
    </section>
    <section class="items-menu color-over logout-mobile">
        <div class="item" (click)="logout()">
            <div class="menu-icon"> <i class="fa-solid fa-sign-out"></i> </div>
            <span>Sair</span>
        </div>
    </section>
</div>