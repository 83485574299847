import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ConfigurationCommand } from 'src/app/commands/configuration.command';
import { LoginCommand } from 'src/app/commands/login.command';
import { ConfigurationModel } from 'src/app/models/configuration.model';
import { LoginModel } from 'src/app/models/login.model';
import { UserModel } from 'src/app/models/user.model';
import { FirebaseService } from 'src/app/services/firebase/firebase.service';
import { InputFormsService } from 'src/app/services/utils/input-forms.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  formValidation: UntypedFormGroup;
  submitted: boolean = false;
  configuration: ConfigurationModel;

  constructor(
    public formBuilder: UntypedFormBuilder,
    public inputFormService: InputFormsService,
    public loginCommand: LoginCommand,
    public router: Router,
    private configurationCommand: ConfigurationCommand,
    private firebaseService: FirebaseService,
  ) { }

  ngOnInit(): void {
    this.firebaseService.viewScreen('login');
    this.formValidation = this.formBuilder.group({
      username: ['', [Validators.required, Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$')]],
      password: ['', [Validators.required]],
      device_token: ['', []],
    });

    this.getConfiguration();
  }

  getConfiguration() {
    this.configurationCommand.get().then((result: ConfigurationModel) => {
      this.configuration = result;
    });
  }

  get errorCtr() {
    return this.formValidation.controls;
  }

  focus(type: string, element: string) {
    if (type == 'focus') {
      this.inputFormService.setInputFocus(document, element, this.formValidation, this.submitted);
    } else if (type == 'unfocus') {
      this.inputFormService.unCheckFocus(document, element);
    }
  }


  async submit() {
    this.firebaseService.eventClick('authenticate', 'login');
    this.submitted = true;

    if (this.formValidation.valid) {
      let loginModel: LoginModel = this.formValidation.value as LoginModel;

      const token: string = await this.firebaseService.getToken() as string;
      loginModel.device_token = token;
      
      this.loginCommand.login(loginModel).then((result: UserModel) => {

        if (result) {
          this.formValidation.reset();
          this.submitted = false;
          localStorage.setItem('lastTokenUpdate', new Date().toISOString());
          this.router.navigate(['/home']);
        }
      });
    }
  }

  openSalesPage() {
    window.open(this.configuration.link_store, "_blank");
  }

}
