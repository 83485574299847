import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PlanModel } from 'src/app/models/plan.model';

@Component({
  selector: 'app-card-plan',
  templateUrl: './card-plan.component.html',
  styleUrls: ['./card-plan.component.scss']
})
export class CardPlanComponent implements OnInit {

  @Input() plan: PlanModel;
  @Input() atual_plan: number;

  constructor(private router: Router,) { }

  ngOnInit(): void { }

  openPlan(plan:PlanModel) {
    this.router.navigate(['pagamento', plan.id]);
  }
}
