<app-auth-header page="user-data"></app-auth-header>

<div class="content" *ngIf="!loading">
    <div class="profile-pages">
        <button class="button-transparent" [routerLink]="'/minha-conta'"><i class="fa-solid fa-user"></i><span>Informações pessoais</span></button>
        <button class="button-transparent" [routerLink]="'/meus-cartoes'"><img src="assets/icons/credit.svg"><span>Meus cartões</span></button>
        <button class="button-transparent active"><img src="assets/icons/rules_green.svg"><span>Histórico de transações</span></button>
    </div>
    <div class="card">
        <table>
            <thead>
                <tr>
                    <th>Tipo</th>
                    <th>Data de criação</th>
                    <th>Status</th>
                    <th>Plataforma</th>
                    <th>Vencimento</th>
                    <th>Conta de negociação</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of listTransactions">
                    <td>
                        <span *ngIf="item.billingType == 'BOLETO'">Boleto</span>
                        <span *ngIf="item.billingType == 'CREDIT_CARD'">Cartão</span>
                        <span *ngIf="item.billingType == 'PIX'">Pix</span>
                    </td>
                    <td>{{item.date_created | date: 'dd/MM/yyyy'}}</td>
                    <td>
                        <span class="color-primary-medium" *ngIf="item.status == 'CONFIRMED' || item.status == 'RECEIVED'">Aprovado</span>
                        <span class="color-warning" *ngIf="item.status == 'PENDING' && verifyDueDate(item.dueDate)">Pendente</span>
                        <span class="color-danger" *ngIf="item.status == 'OVERDUE' || (item.status == 'PENDING' && !verifyDueDate(item.dueDate))">Vencido</span>
                    </td>
                    <td>
                        <span *ngIf="item.platform == 'MT5' || !item.platform">Meta Trader 5</span>
                        <span *ngIf="item.platform == 'BA'">Black Arrow</span>
                    </td>
                    <td>{{item.dueDate| date: 'dd/MM/yyyy'}}</td>
                    <td>
                        <a class="account-link" *ngIf="item.account_operation" [routerLink]="'/desempenho/' + item.account_operation.account">{{item.account_operation.account}}</a>
                    </td>
                    <td class="action">
                        <i class="fa-solid fa-download" *ngIf="item.billingType == 'BOLETO' && item.status == 'PENDING' && verifyDueDate(item.dueDate)" (click)="openBoleto(item.bankSlipUrl)"></i>
                        <i class="fa-regular fa-eye" *ngIf="item.billingType == 'PIX' && item.status == 'PENDING' && verifyDueDate(item.dueDate)" (click)="openPix(item.id)"></i>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>