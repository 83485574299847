import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SidenavService {
  retract : boolean = true;
  enabled : boolean = true;
  loading : boolean = false;
  private toggleBehaviorSubject = new BehaviorSubject<boolean>(true);
  private enableBehaviorSubject = new BehaviorSubject<boolean>(true);
  private loadingBehaviorSubject = new BehaviorSubject<boolean>(false);


  onSidenavToggle() {
    this.retract = !this.retract;
    this.toggleBehaviorSubject.next(this.retract);
  }

  getSidenavToogle() {
    return this.toggleBehaviorSubject;
  }

  enableSidenav() {
    this.enabled = true;
    this.enableBehaviorSubject.next(this.enabled);
  }

  disableSidenav() {
    this.enabled = false;
    this.enableBehaviorSubject.next(this.enabled);
  }

  getEnableSidenav() {
    return this.enableBehaviorSubject;
  }

  disableProgressBar() {
    this.loading = false;
    this.loadingBehaviorSubject.next(this.loading);
  }

  enableProgressBar() {
    this.loading = true;
    this.loadingBehaviorSubject.next(this.loading);
  }
  
  getProgressBar() {
    return this.loadingBehaviorSubject;
  }
  
  constructor() { }
}
