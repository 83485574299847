import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpInterceptor, HttpResponse } from '@angular/common/http';
import { HttpRequest } from '@angular/common/http';
import { HttpHandler } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { tap } from "rxjs/operators";
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable()
export class Interceptor implements HttpInterceptor {
    constructor(
        private router: Router,) { }

    intercept(request: HttpRequest<any>, next: HttpHandler):
        Observable<HttpEvent<any>> {
        // this.loginService = new LoginService(null);

        let token: string = localStorage.getItem('token') as string;

        if (!request.url.includes('/api/v1/account/token/')
        && !request.url.includes('/api/v1/account/password-reset/')
        && !request.url.includes('/api/v1/account/password-reset/confirm/')
        && !request.url.includes('/api/v1/account/plan/public/')
        && !request.url.includes('/api/v1/account/account-confirm/')
        && !request.url.includes('/api/v1/account/app-configuration/')
        && !request.url.includes('/api/v1/method-payment/make-payment/')
        && (!request.url.includes('/api/v1/account/privacy-policy/') || request.url.includes('/api/v1/account/privacy-policy/auth/'))
        && (!request.url.includes('/api/v1/account/terms-use/') || request.url.includes('/api/v1/account/terms-use/auth/'))
        && !request.url.includes('/api/v1/account/user-coupon/register/')
        && !request.url.includes('/api/v1/account/coupon/check-coupon/')
        && !request.url.includes('/api/v1/method-payment/one-off-charge/')) {

            request = request.clone({
                headers: request.headers
                    .append('Authorization', `Bearer ${token}`)
            });
        }

        return next.handle(request).pipe(tap(() => { },
            (err: any) => {
                if (err instanceof HttpErrorResponse) {
                    if (err.status != 401 && err.status != 403) {
                        return;
                    }
                    localStorage.clear()
                    this.router.navigate(['login']);
                }
            })
        );
    }
}
