import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserCommand } from 'src/app/commands/user.command';
import { SidenavService } from 'src/app/components/sidenav/sidenav.service';
import { FirebaseService } from 'src/app/services/firebase/firebase.service';

@Component({
  selector: 'app-account-confirm',
  templateUrl: './account-confirm.component.html',
  styleUrls: ['./account-confirm.component.scss']
})
export class AccountConfirmComponent implements OnInit {

  title: string;
  subtitle: string;
  loading: boolean = false;
  success: boolean = false;

  constructor(
    private routeInfo: ActivatedRoute,
    private userCommand: UserCommand,
    private firebaseService: FirebaseService,
    private router: Router,
    private sidenavService: SidenavService,
  ) { }

  ngOnInit() {
    this.sidenavService.getProgressBar().subscribe(
      value => {
        this.loading = value;
      }
    );

    this.routeInfo.queryParams.subscribe((params: any) => {
      if (params) {
        this.userCommand.activateAccount(params.token).then((result: boolean) => {
          this.generateContent(result ? 'success' : 'denied');
        });
      } else {
        this.generateContent();
      }
    });
  }

  generateContent(result: string = '') {
    switch (result) {
      case 'denied':
        this.title = "Ops  :(";
        this.subtitle = "Não conseguimos validar o seu token. <br> Entre em contato com o nosso suporte através do e-mail suporte@insiderinveste.com.br que responderemos você o mais breve possível."
        this.success = false;
        break;
      case 'success':
        this.title = "Olá! Seja Bem -Vindo(a) ao Insider Investe, sua plataforma de mesa proprietária!";
        this.subtitle = "Estamos felizes em ter você conosco! \n Clique no botão abaixo e para acessar a sua plataforma.";
        this.success = true;
        break;
      default:
        this.title = "Ops  :(";
        this.subtitle = "Não conseguimos identificar o seu token de ativação. <br> Entre em contato com o nosso suporte através do e-mail suporte@insiderinveste.com.br que responderemos você o mais breve possível."
        this.success = false;
        break;
    }
  }

  redirectAccount() {
    this.firebaseService.eventClick(`redirect-account-login`, 'account-confirm');
    this.router.navigate(['/login']);
  }
}
