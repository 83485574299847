<app-simple-header></app-simple-header>

<div class="content">
    <div class="title">
        Ativos permitidos para negociações
    </div>
    <section class="row-content">
        <div class="card-filter">
            <div class="card">
                <span class="desc">Selecione um plano para visualizar os ativos e quantidade de contratos permitidos para cada um deles</span>

                <div class=" filter">
                    <div class="select">
                        <mat-form-field>
                            <mat-select placeholder="Planos" [(ngModel)]="plan_id">
                                <mat-option *ngFor="let plan of listPlans" [value]="plan.id">
                                    <span>{{plan.title}}</span>
                                    <span *ngIf="!plan.is_stock"> (Índices e derivativos)</span>
                                    <span *ngIf="plan.is_stock"> (Ações Americanas)</span>
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <button class="button-primary" (click)="seach()">
                        <span>Buscar</span>
                    </button>
                </div>
            </div>
        </div>
    </section>

    <div class="plan_title" *ngIf="plan">
        <div>
            <div class="title">
                {{plan.title}}
            </div>
            <div class="subtitle">
                <span>Saldo inicial da conta: </span>
                <span class="value">{{plan.value_account | currency: '$'}}</span>
            </div>
        </div>
        <div class="input">
            <input class="input-box" type="text" placeholder="Procurar" #filter (keyup)="filterAssets(filter.value)">
            <i class="fa-solid fa-magnifying-glass"></i>
        </div>
    </div>
    <span class="desc desc-alert" *ngIf="plan && plan.is_stock">
        <span class="color-danger">* </span> No âmbito do plano de ações, as contas disponíveis não possuem alavancagem. É de
        responsabilidade exclusiva do trader calcular os lotes que podem ser operados com base em seu
        saldo atual. Esta responsabilidade inclui a avaliação cuidadosa das posições que o trader
        pretende assumir, assegurando que estas não ultrapassem o saldo disponível na conta. 
        <br><br>
        Para calcular o tamanho de sua posição é necessário multiplicar a quantidade de contratos com o valor
        atual da ação. Essa regra leva em consideração todas as posições que o trader deseja abrir,
        todas elas somadas não deve ultrapassar o saldo atual da conta.
        <br><br>
        Caso a operação descumpra este requisito, o trade será invalidado pelo sistema. 
    </span>
    <section class="row-content table-assets" *ngIf="plan">
        <app-table-assets *ngIf="listAssets" [assets]="listAssets" [showContract]="!plan.is_stock" [(page)]="page"></app-table-assets>
    </section>
</div>