<app-auth-header page="home"></app-auth-header>

<div class="content" *ngIf="account_operation">

    <section class="section-buttons">
        <section class="metatrader">
            <button class="card" *ngIf="account_operation.status == 2 && (account_operation.platform_type == 1 && (account_operation.accept_value_retention || account_operation.coupon_id > 0))">
                <app-button-icon-label icon="unsecurity" description="Credenciais" (click)="viewCredentials(account_operation)"></app-button-icon-label>
            </button>
            <button class="card" *ngIf="account_operation.platform_type == 1">
                <app-button-icon-label icon="info" description="Eventos da conta" (click)="viewLog(account_operation)"></app-button-icon-label>
            </button>
            <button class="card">
                <app-button-icon-label icon="rule" description="Regras e objetivos" (click)="goRules()"></app-button-icon-label>
            </button>
            <button class="card" *ngIf="account_operation.file_certificate && account_operation.type == 2">
                <app-button-icon-label icon="download" description="Download do certificado" (click)="downloadCerfificate()"></app-button-icon-label>
            </button>
        </section>
        <section class="blackarrow">
            <button class="button-secondary" *ngIf="account_operation.type == 2 && allowWithdraw" (click)="requestWithdraw()">
                {{descriptionButton}}
            </button>
            <button class="button-secondary import" *ngIf="account_operation.platform_type == 2" [routerLink]="'/importar-operacoes/'+account_operation.id">
                <img src="assets/icons/upload.svg">Importar Operações
            </button>
        </section>
    </section>
    <section class="alert" *ngIf="account_operation.platform_type == 1 && (!account_operation.enabled_autotrading || !account_operation.permitedll)">
        <div class="alert-title">O Insider Investe precisa da sua atenção</div>
        <div class="alert-description">Percebemos que uma configuração do seu Insider Investe precisa de um pequeno ajuste, é necessário que as configurações de <strong>"Algotrading"</strong> e <strong>"Permitir DLL estejam habilitadas"</strong>. Para corrigir <a href="/habilitar-algotrading" class="text-click">clique aqui</a> e siga o passo a passo. </div>
    </section>
    <section class="alert" *ngIf="account_operation.platform_type == 1 && (account_operation.last_version_sync_metavision < dashboard.configuration.version_metavision)">
        <div class="alert-title">O Insider Investe precisa da sua atenção</div>
        <div class="alert-description">
            Uma nova versão do Insider Investe está disponível para download. Por favor, atualize para a nova versão o mais rápido possível. Esta atualização é obrigatória para continuar utilizando o Insider Investe com todos os recursos e correções mais recentes. 
            Para atualizar, <a href="/atualizar-real-time" class="text-click">clique aqui</a> e siga as instruções de instalação.</div>
    </section>

    <section class="alert" *ngIf="alert_plan_renovation && account_operation.test_account_operation.status == 1">
        <div class="alert-title">{{alert_plan_renovation.title}}</div>
        <div class="alert-description">{{alert_plan_renovation.description}}</div>
    </section>
    <button *ngIf="alert_plan_renovation && account_operation.test_account_operation.status == 1" class="button-primary open-sales" (click)="openSalesLink()">{{alert_plan_renovation.button}}</button>

    <section class="row-content row-performance-account">
        <div class="card-performance">
            <div class="title">
                <span>Desempenho da conta - {{account_operation.plan.title}} </span>
                <span *ngIf="!account_operation.plan.is_stock">(Índices e derivativos)</span>
                <span *ngIf="account_operation.plan.is_stock">(Ações Americanas)</span>
            </div>
            <div class="card"> 
                <div id="graph-evolution"></div>
                <span class="obs" *ngIf="account_operation.platform_type == 1">
                    <span class="emph">*</span>
                    A sincronização das operações com a plataforma pode levar alguns minutos.
                </span>
            </div>
        </div>
        <div class="data-account">
            <div class="title">Dados da conta {{account_operation.account}}</div>
            <div class="card">
                <div class="item-info" *ngIf="account_operation.type == 1">
                    <div class="item-info-description">Resultado</div>
                    <div class="item-info-value first-item-info-value color-warning" *ngIf="account_operation.status == 2">Em teste</div>
                    <div class="item-info-value first-item-info-value color-info" *ngIf="account_operation.status == 8">Aguardando aprovação</div>
                    <div class="item-info-value first-item-info-value color-info" *ngIf="account_operation.status == 9">Aguardando assinatura</div>
                    <div class="item-info-value first-item-info-value color-primary-medium" *ngIf="account_operation.status == 4">Aprovado</div>
                    <div class="item-info-value first-item-info-value color-danger" *ngIf="account_operation.status == 6">Reprovado</div>
                </div>
                <div class="item-info" *ngIf="account_operation.type == 2">
                    <div class="item-info-description">Conta</div>
                    <div class="item-info-value ">{{account_operation.account}}</div>
                </div>
                <div class="item-info" *ngIf="account_operation.platform_type == 1">
                    <div class="item-info-description">Saldo da plataforma</div>
                    <div class="item-info-value">$ {{getGrossBalance(account_operation.account_balance, dashboard.invalidated_operations) | number:'1.2-2'}}</div>
                </div>
                <div class="item-info" *ngIf="account_operation.platform_type == 1">
                    <div class="item-info-description">
                        Saldo invalidado da conta
                        <i class="fa-regular fa-circle-question" matTooltip="Saldo invalidado representa operações que não respeitaram as regras da mesa."></i>
                    </div>
                    <div class="item-info-value color-danger">$ {{dashboard.invalidated_operations | number:'1.2-2'}}</div>
                </div>
                <div class="item-info">
                    <div class="item-info-description">Saldo líquido da conta</div>
                    <div class="item-info-value">$ {{account_operation.account_balance | number:'1.2-2'}}</div>
                </div>
                <div class="item-info">
                    <div class="item-info-description">Limite mínimo da conta</div>
                    <div class="item-info-value">$ {{account_operation.trailing_drawdown | number:'1.2-2'}}</div>
                </div>
                <div class="item-info">
                    <div class="item-info-description">Status</div>
                    <div class="item-info-value color-primary-medium" *ngIf="account_operation.activated && account_operation.status == 2">Ativa</div>
                    <div class="item-info-value color-danger" *ngIf="!account_operation.activated || account_operation.status > 2">Inativa</div>
                </div>
                <div class="item-info">
                    <div class="item-info-description">Plataforma</div>
                    <div class="item-info-value">{{account_operation.platform_type == 1? 'Meta Trader 5': 'Black Arrow'}}</div>
                </div>
                <div class="item-info" *ngIf="account_operation.platform_type == 1">
                    <div class="item-info-description">Última sincronização</div>
                    <div class="item-info-value last-item-info-value">{{ account_operation.last_sync_platform | date:'dd/MM/yyyy HH:mm'}}</div>
                </div>
                <div class="item-info" *ngIf="account_operation.end_date_plan && !account_operation.lifetime">
                    <div class="item-info-description">Data da próxima renovação</div>
                    <div class="item-info-value last-item-info-value">{{ account_operation.end_date_plan | date:'dd/MM/yyyy'}}</div>
                </div>
            </div>
        </div>
    </section>

    <section class="row-content">
        <div class="card-consistence">
            <div class="title">Consistência</div>
            <div class="card">
                <div class="progress-consistence">
                    <div class="progress-low"></div>
                    <div class="progress-medium"><span>{{consistency.percent | number:'1.2-2' }}%</span></div>
                    <div class="progress-high"></div>
                    <div class="div-opacity" [ngStyle]="{'left.%': consistency.percent}"></div>
                </div>
            </div>
        </div>
    </section>

    <section class="row-content">
        <div class="result-recents">
            <div class="title">Resultados recentes</div>
            <div class="card">
                <div class="item-info-description">
                    <div>Perda permitida hoje</div>
                    <div>Perda máxima permitida para a conta</div>
                    <div>Lucros de hoje</div>
                </div>
                <div class="item-info-value">
                    <div>$ {{getAllowableLossDay() | number:'1.2-2'}}</div>
                    <div>$ {{getAllowableLossAccount() | number:'1.2-2'}}</div>
                    <div>$ {{profit_today | number:'1.2-2'}}</div>
                </div>
            </div>
        </div>
    </section>

    <section class="row-content" id="regras">
        <div class="rules">
            <div class="title">Minhas operações</div>
            <app-table-operations [account]="account_operation.id"></app-table-operations>
        </div>
    </section>

    <section class="row-content">
        <div class="statistics">
            <div class="title">Estatísticas</div>
            <div class="card">
                <div class="row-card first-row-card">
                    <div class="item-info">
                        <div class="item-info-description">Total de ganhos</div>
                        <div class="item-info-value color-primary-medium">$ {{performance.lucro_bruto | number:'1.2-2'}}</div>
                    </div>
                    <div class="item-info">
                        <div class="item-info-description">Total de perdas</div>
                        <div class="item-info-value color-danger">-$ {{(performance.prejuizo_bruto * -1) | number:'1.2-2'}}</div>
                    </div>
                    <div class="item-info">
                        <div class="item-info-description">Total de operações</div>
                        <div class="item-info-value color-medium">{{performance.numero_total_operacoes}}</div>
                    </div>
                </div>
                <div class="row-card to-color-row-card">
                    <div class="item-info">
                        <div class="item-info-description">Média de ganhos</div>
                        <div class="item-info-value color-primary-medium">$ {{performance.media_operacoes_vencedoras | number:'1.2-2'}}</div>
                    </div>
                    <div class="item-info">
                        <div class="item-info-description">Média de perdas</div>
                        <div class="item-info-value color-danger">-$ {{(performance.media_operacoes_perdedoras * -1) | number:'1.2-2'}}</div>
                    </div>
                    <div class="item-info">
                        <div class="item-info-description">Quant. op. vencedoras</div>
                        <div class="item-info-value color-primary-medium">{{performance.numero_total_operacoes_positivas}}</div>
                    </div>
                </div>
                <div class="row-card">
                    <div class="item-info">
                        <div class="item-info-description">Maior ganho</div>
                        <div class="item-info-value color-primary-medium">$ {{performance.maior_operacao_positiva_dolar | number:'1.2-2'}}</div>
                    </div>
                    <div class="item-info">
                        <div class="item-info-description">Maior perda</div>
                        <div class="item-info-value color-danger">-$ {{(performance.maior_operacao_negativa_dolar * -1) | number:'1.2-2'}}</div>
                    </div>
                    <div class="item-info">
                        <div class="item-info-description">Quant. op. perdedoras</div>
                        <div class="item-info-value color-danger">{{performance.numero_total_operacoes_negativas}}</div>
                    </div>
                </div>
                <div class="row-card last-row-card to-color-row-card">
                    <div class="item-info">
                        <div class="item-info-description">Taxa de acerto</div>
                        <div class="item-info-value color-primary-medium">{{performance.taxa_acerto | number:'1.2-2'}}%</div>
                    </div>
                    <div class="item-info">
                        <div class="item-info-description">Fator de risco</div>
                        <div class="item-info-value" [ngClass]="{'color-danger': valueProfitLoss < 0, 'color-primary-medium': valueProfitLoss >= 0}">1 : {{valueProfitLoss | number:'1.2-2'}}</div>
                    </div>
                    <div class="item-info">
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section>
        <span class="obs">
            <span class="emph">*</span>
            Caso haja divergência nos valores apresentados na Dashboard com o Meta Trader 5, recomendamos que você continue <strong>operando normalmente</strong> e entre em contato com o suporte para informar sobre os valores divergentes</span>
    </section>
</div>