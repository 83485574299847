<app-auth-header page="user-data"></app-auth-header>

<div class="content">
    <div class="profile-pages">
        <button class="button-transparent active"><i class="fa-solid fa-user"></i><span>Informações pessoais</span></button>
        <button class="button-transparent" [routerLink]="'/meus-cartoes'"><img src="assets/icons/credit.svg"><span>Meus cartões</span></button>
        <button class="button-transparent" [routerLink]="'/minhas-transacoes'"><img src="assets/icons/rules_green.svg"><span>Histórico de transações</span></button>
    </div>
    <div class="center-card">
        <div class="card">
            <div class="card-title">Alterar senha</div>
    
            <form [formGroup]="formValidation">
    
                <div class="input input-new-password">
                    <input class="input-box" type="password" (focus)="focus('focus', 'current_password')" [class.input-box-error]="submitted && errorCtr.current_password.errors?.required"
                        (blur)="focus('unfocus', 'current_password')" formControlName="current_password" placeholder="Informe sua senha atual">
        
                    <span class="error-form" *ngIf="submitted && errorCtr.current_password.errors?.required">
                        O campo senha atual é obrigatório
                    </span>
                </div>
        
                <div class="input input-new-password">
                    <input class="input-box" type="password" (focus)="focus('focus', 'new_password1')" [class.input-box-error]="submitted && (errorCtr.new_password1.errors?.required || errorCtr.new_password1.errors?.minlength)"
                        (blur)="focus('unfocus', 'new_password1')" formControlName="new_password1" placeholder="Informe sua nova senha">
        
                    <span class="error-form" *ngIf="submitted && errorCtr.new_password1.errors?.required">
                        O campo senha é obrigatório
                    </span>
        
                    <span class="error-form" *ngIf="submitted && errorCtr.new_password1.errors?.minlength">
                        A senha deve conter pelo menos 08 caracteres
                    </span>
                </div>
        
                <div class="input">
                    <input class="input-box" type="password" (focus)="focus('focus', 'new_password2')" [class.input-box-error]="submitted && (errorCtr.new_password2.errors?.required || (!errorCtr.new_password2.errors?.required && formValidation.hasError('mismatchedPasswords')))"
                        (blur)="focus('unfocus', 'new_password2')" formControlName="new_password2" placeholder="Repita a sua senha">
        
        
                    <span class="error-form" *ngIf="submitted && errorCtr.new_password2.errors?.required">
                        O campo confirmar senha é obrigatório
                    </span>
        
                    <span class="error-form"
                        *ngIf="submitted && (!errorCtr.new_password2.errors?.required && formValidation.hasError('mismatchedPasswords'))">
                        As senhas não conferem
                    </span>
        
                    <span class="error-form"
                        *ngIf="submitted && messageError">
                        {{messageError}}
                    </span>
                </div>
            </form>
        
            <button class="button-primary" (click)="submit()">Alterar senha</button>
        </div>
    </div>
</div>