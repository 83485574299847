<div class="header">
    <img class="logo" src="assets/images/logo_white.png" [routerLink]="'/home'">

    <section class="items-header header-web">
        <div class="item" [class.color-primary-medium]="page == 'home'" (click)="selectPage('home')">
            <i class="fa-solid fa-house"></i>
            <span>Início</span>
        </div>
        <div class="item" [class.color-primary-medium.active]="page == 'assets-allowed'" [class.active]="page == 'assets-allowed'" (click)="selectPage('ativos-permitidos')"
            *ngIf="pagesContentService.isVisible('ativos-permitidos')">
            <img src="assets/icons/graph_green.svg">
            <span>Ativos e horários operacionais</span>
        </div>
        <div class="item" [class.color-primary-medium.active]="page == 'rules-objectives'" [class.active]="page == 'rules-objectives'" (click)="selectPage('regras-objetivos')"
            *ngIf="pagesContentService.isVisible('regras-objetivos')">
            <img src="assets/icons/rules_green.svg">
            <span>Regras e Objetivos</span>
        </div>
        <div class="item" [class.color-primary-medium]="page == 'user-data'" (click)="selectPage('minha-conta')"
            *ngIf="pagesContentService.isVisible('minha-conta')">
            <i class="fa-solid fa-user"></i>
            <span>Minha conta</span>
        </div>
    </section>

    <section class="items-header header-mobile">
        <div class="item" (click)="toggleMenu()">
            <i class="fa-solid fa-bars"></i>
        </div>
    </section>
    <section class="items-header header-web logout-web">
        <div class="item item-support" (click)="faq()">
            <i class="fa-solid fa-question"></i>
        </div>
        <div class="item item-support" (click)="support()">
            <i class="fa-solid fa-headset"></i>
        </div>
        <div class="item" (click)="logout()">
            <i class="fa-solid fa-sign-out"></i>
        </div>
    </section>
</div>