import { Component, Input, OnInit } from '@angular/core';
import { SidenavService } from '../sidenav/sidenav.service';
import { Router } from '@angular/router';
import { LoginCommand } from 'src/app/commands/login.command';
import { ModalService } from 'src/app/services/utils/modal.service';
import { SupportInfoComponent } from '../support-info/support-info.component';
import { PagesContentService } from 'src/app/services/utils/pages-content.service';
import { AlertService } from '../alert/alert.service';

@Component({
  selector: 'app-auth-header',
  templateUrl: './auth-header.component.html',
  styleUrls: ['./auth-header.component.scss']
})
export class AuthHeaderComponent implements OnInit {

  @Input() page: string = '';

  retract: boolean = false;

  constructor(
    private sidenavService: SidenavService,
    private router: Router,
    private loginCommand: LoginCommand,
    private modalService: ModalService,
    public pagesContentService: PagesContentService,
    private alertComponent: AlertService
  ) { }

  ngOnInit(): void {
  }

  toggleMenu() {
    this.sidenavService.onSidenavToggle();
    this.sidenavService.getSidenavToogle().subscribe(
      value => {
        this.retract = value;
      }
    );
  }

  logout() {
    const token: string = localStorage.getItem("token") as string;
    this.loginCommand.logout(token);
    localStorage.clear();
    this.router.navigate(['/login']);
  }

  faq() {
    this.router.navigate(['perguntas-frequentes']);
  }

  support() {
    this.modalService.openModal(SupportInfoComponent)
  }

  selectPage(page) {
    let page_block = this.pagesContentService.getBlock(page);

    if (page_block) {
      this.alertComponent.presentAlert("warning", "Conteúdo indisponível", page_block.message, '', 3);
    } else {
      this.router.navigate([`/${page}`]);
    }
  }
}
