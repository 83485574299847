<div class="plan" [class.stock]="plan.is_stock">
    <div class="plan-header">
        <div class="plan-title">{{plan.title}}</div>
        <div class="plan-value"><span class="symbol">$</span> {{plan.value_account | number:'1.2-2'}} na conta</div>
        <div class="plan-type" *ngIf="plan.tag">{{plan.tag}}</div>
    </div>
    <div class="plan-content">
        <div class="month-value">
            <div class="high"><span class="currency">R$</span>&nbsp;<span class="value">{{plan.value_plan | number:'1.2-2'}}</span></div>
            <div class="low">Mensal</div>
        </div>
        <div class="list-itens">
            <div class="item">
                <div class="item-inner">
                    <i class="fa-regular fa-circle-check"></i>
                    <span>{{plan.companies}}</span>
                </div>
            </div>
            <div class="item">
                <div class="item-inner">
                    <i class="fa-regular fa-circle-check"></i>
                    <span>Meta de Lucro ${{plan.profit_target | number:'1.2-2'}}</span>
                </div>
            </div>
            <div class="item">
                <div class="item-inner">
                    <i class="fa-regular fa-circle-check"></i>
                    <span>Perda máxima ${{plan.trailing_drawdown | number:'1.2-2'}}</span>
                </div>
            </div>
            <div class="item">
                <div class="item-inner">
                    <i class="fa-regular fa-circle-check"></i>
                    <span *ngIf="plan.limit_loss_day > 0">Perda Máxima Dia ${{plan.limit_loss_day | number:'1.2-2'}}</span>
                    <span *ngIf="plan.limit_loss_day <= 0">Perdas no dia ilimitada</span>
                </div>
            </div>
            <div class="item">
                <div class="item-inner">
                    <i class="fa-regular fa-circle-check"></i>
                    <span>Mínimo de Dias Operados {{plan.minimum_days_operation}}</span>
                </div>
            </div>
            <div class="item" *ngIf="plan.limit_loss_operation > 0">
                <div class="item-inner">
                    <i class="fa-regular fa-circle-check"></i>
                    <span>Perda Máxima Operação ${{plan.limit_loss_operation |
                        number:'1.2-2'}}</span>
                </div>
            </div>
            <div class="item">
                <div class="item-inner">
                    <i class="fa-regular fa-circle-check"></i>
                    <span>{{plan.pass_on}}</span>
                </div>
            </div>
            <div class="item">
                <div class="item-inner">
                    <i class="fa-regular fa-circle-check"></i>
                    <span>{{plan.profit}}% de Lucro</span>
                </div>
            </div>
        </div>
        <div class="plan-footer">
            <button class="button-primary" *ngIf="plan.id != atual_plan" (click)="openPlan(plan)">Escolher plano</button>
            <div class="list-assets" *ngIf="plan.assets_description">
                Ativos:
                <span>{{plan.assets_description}}</span>
            </div>
        </div>
    </div>
</div>