import { Component } from '@angular/core';
import { AlertEvent } from './services/utils/alert.service';
import { NavigationEnd, Router } from '@angular/router';
import { SidenavService } from './components/sidenav/sidenav.service';
import { LoginCommand } from './commands/login.command';
import { FirebaseService } from './services/firebase/firebase.service';
import { FirebaseMessagingService } from './services/firebase/firebase-messaging.service';
import { ConfigurationModel } from './models/configuration.model';
import { ConfigurationCommand } from './commands/configuration.command';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  configuration: ConfigurationModel;
  
  retract: boolean;
  enabled: boolean;
  loading: boolean;
  hideWPPSupport: boolean = true;

  constructor(
    public alertEvent: AlertEvent,
    private router: Router,
    private sidenavService: SidenavService,
    private loginCommand: LoginCommand,
    private firebaseService: FirebaseService,
    private firebaseMessagingService: FirebaseMessagingService, 
    private configurationCommand: ConfigurationCommand,
  ){
    this.router.events.subscribe(event => {
      if(event instanceof NavigationEnd)
        this.hideWPPSupport = event.url.split('?')[0] != '/ativos';
    });
  }

  ngOnInit() {
    this.sidenavService.getSidenavToogle().subscribe(
      value => {
        this.retract = value;
      }
    );

    this.sidenavService.getEnableSidenav().subscribe(
      value => {
        this.enabled = value;
      }
    );

    this.sidenavService.getProgressBar().subscribe(
      value => {
        setTimeout(() => {
          this.loading = value;
        }, 500);
      }
    );

    this.initFirebase();
    this.getConfiguration();
  } 

  getConfiguration() {
    this.configurationCommand.get().then((result: ConfigurationModel) => {
      this.configuration = result;
      this.checkMaintence();
    });
  }

  initFirebase() {
    
    this.firebaseMessagingService.requestPermission().subscribe(
      {
        next: (token) => { },
        error: (error) => { }
      }
    );

    this.firebaseMessagingService.receiveMessage().subscribe(
      {
        next: (payload) => {
          if(payload.data.last_sync_platform)
            localStorage.setItem('last_sync_platform', payload.data.last_sync_platform)

          if(payload.data.reload == 'True')
            window.location.reload()

        },
        error: (error) => { }
      }
    );
  }

  checkMaintence() {
    if (this.configuration && this.configuration.under_maintence) {
      localStorage.setItem('maintenance', 'Y');
    } else {
      localStorage.setItem('maintenance', 'N');
    }
  }
}
