<div class="content-body">
    <img class="close-modal" (click)="closeModal()" src="assets/icons/close.svg">

    <div class="content-body-title">Dados da conta de negociação</div>
    
    <section class="content-body-info" *ngIf="account">
        <div class="content-body-info-item">
            <div class="align-start">Conta:</div>
            <div class="align-center color-medium">{{account.account}}</div>
            <div class="align-end"><img src="assets/icons/copy.svg" alt="Copiar" (click)="copyText(account.account, 'Conta copiada com sucesso')"></div>
        </div>
        <div class="content-body-info-item">
            <div class="align-start">Senha:</div>
            <div class="align-center color-medium">{{account.password}}</div>
            <div class="align-end"><img src="assets/icons/copy.svg" alt="Copiar" (click)="copyText(account.password, 'Senha copiada com sucesso')"></div>
        </div>
        <div class="content-body-info-item">
            <div class="align-start">Servidor:</div>
            <div class="align-center color-medium">ActivTrades-Server</div>
            <div class="align-end"><img src="assets/icons/copy.svg" alt="Copiar" (click)="copyText('ActivTrades-Server', 'Servidor copiado com sucesso')"></div>
        </div>
        <div class="content-body-info-item">
            <div class="align-start">Plataforma:</div>
            <div class="align-center color-medium">MetaTrader 5</div>
            <div class="align-end"><i class="fa-solid fa-download" (click)="downloadMT5()"></i></div>
        </div>
        <div class="content-body-info-item account-configuration">
            <div class="align-start">Configuração da conta:</div>
            <div class="align-center color-medium">{{account.account_configuration}}</div>
            <div class="align-end"></div>
        </div>
    </section>
    <section class="content-body-footer" *ngIf="account">
        A configuração da conta é realizada através do Insider Investe Real Time. É de extrema importância que durante todo o período operacional o Insider Investe Real Time esteja ativado na sua plataforma de negociação.
    </section>

    <div class="space"></div>

    <section *ngIf="!account">Não foi possível identificar a conta de negociação</section>
</div>