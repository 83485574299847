import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountOperationCommand } from 'src/app/commands/account-operation.command';
import { WithdrawCommand } from 'src/app/commands/withdraw.command';
import { AccountOperationModel } from 'src/app/models/account-operation.model';
import { InputFormsService } from 'src/app/services/utils/input-forms.service';
import { ModalService } from 'src/app/services/utils/modal.service';
import { RequestModalComponent } from '../request-modal/request-modal.component';
import { AlertService } from 'src/app/components/alert/alert.service';
import { WithdrawAvailableModel, WithdrawModel } from 'src/app/models/withdraw.model';
import { PagesContentService } from 'src/app/services/utils/pages-content.service';
import { ConfirmRequestComponent } from '../confirm-request/confirm-request.component';

@Component({
  selector: 'app-withdraw-history',
  templateUrl: './withdraw-history.component.html',
  styleUrls: ['./withdraw-history.component.scss']
})
export class WithdrawHistoryComponent {
  loading: boolean = false;
  listWithDraw: WithdrawModel[] = [];
  listAccounts: AccountOperationModel[] = [];
  account_operation: AccountOperationModel;
  withdraw_value: string = '';
  withdraw_error: boolean = false;
  avaliable: WithdrawAvailableModel;
  showForm: boolean = false;

  constructor(
    private accountOperationCommand: AccountOperationCommand,
    private withdrawCommand: WithdrawCommand,
    private router: Router,
    private inputFormsService: InputFormsService,
    private modalService: ModalService,
    private alertComponent: AlertService,
    private contentPageService: PagesContentService,
    private activatedRoute: ActivatedRoute,
  ){ }

  ngOnInit() {
    setTimeout(() => {
      let page_block = this.contentPageService.getBlock('extrato');

      if(page_block){
        this.alertComponent.presentAlert("warning", "Conteúdo indisponível", page_block.message);
        this.router.navigate(['home']);
      }
    },1000);
    const today = new Date().getDate();
    this.showForm = 1 <= today && today <= 5;
    this.activatedRoute.params.subscribe(async (params: any) => {
      if (params.id) {
        this.accountOperationCommand.get(params.id).then((result: AccountOperationModel[]) => {
          if (result) {
            this.account_operation = result[0];
            this.seach();
          }
        });
      }
    });
  }

  seach() {
    this.listWithDraw = [];
    this.withdraw_value = '';
    setTimeout(() => {
      Promise.all([
        this.withdrawCommand.get(this.account_operation.id),
        this.withdrawCommand.available(this.account_operation.id),
      ]).then((data: any) => {
        let result = data[0], available = data[1];
        if(result && result.results)
          this.listWithDraw = result.results;
        if(available && available.data)
          this.avaliable = available.data;
      });
    }, 100);
  }

  maskMoney() {
    this.withdraw_value = this.inputFormsService.money(this.withdraw_value);
  }

  afterWithdraw() {
    const value = Number(this.withdraw_value.replace('$', '').replace('.', '').replace(',', '.'));
    if(value > (this.avaliable.withdraw_available) || value < 0){
      this.withdraw_error = true;
      return this.avaliable.withdraw_available;
    }
    this.withdraw_error = false;
    return this.avaliable.withdraw_available - value;
  }

  confirmRequest() {
    const value = Number(this.withdraw_value.replace('$', '').replace('.', '').replace(',', '.'));
    if(value> 0 && value <= this.avaliable.withdraw_available) {
      this.modalService.openModal(ConfirmRequestComponent, '400px', null, null, (confirm: boolean) => {
        if(confirm)
          this.request()
      });
    }
  }

  request() {
    const value = Number(this.withdraw_value.replace('$', '').replace('.', '').replace(',', '.'));
    this.modalService.setContent({value: value, account_operation: this.account_operation.id});
    this.modalService.openModal(RequestModalComponent, '800px', null, null, (result: boolean) => {
      if(result)
        setTimeout(() => {
          this.seach();
        }, 1000);  
    });
  }
}
