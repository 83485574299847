<app-auth-header page="user-data"></app-auth-header>

<div class="content" *ngIf="!loading">
    <div class="profile-pages">
        <button class="button-transparent" [routerLink]="'/minha-conta'"><i class="fa-solid fa-user"></i><span>Informações pessoais</span></button>
        <button class="button-transparent active"><img src="assets/icons/credit.svg"><span>Meus cartões</span></button>
        <button class="button-transparent" [routerLink]="'/minhas-transacoes'"><img src="assets/icons/rules_green.svg"><span>Histórico de transações</span></button>
    </div>
    <div class="card">
        <table>
            <thead>
                <tr>
                    <th>Número do cartão</th>
                    <th>Bandeira</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of listCreditCard">
                    <td>**** **** **** {{item.credit_card_number}}</td>
                    <td class="card-flag">{{item.credit_card_brand}}</td>
                    <td class="trash" (click)="deleteCard(item.id)"><i class="fa-solid fa-trash"></i></td>
                </tr>
            </tbody>
        </table>
    </div>
</div>