<app-auth-header page="history"></app-auth-header>

<div class="content" *ngIf="!loading">
    <div class="title">
        Saques (BETA)
    </div>
    <section class="row-content">
        <div class="card-filter">
            <div class="card">
                <span class="desc">Selecione a conta de negociação para visualizar seu histórico e solicitar novos saque</span>
                <div class=" filter">
                    <div class="select">
                        <mat-form-field>
                            <mat-select placeholder="Conta de negociação" [(ngModel)]="selectedAccount">
                                <mat-option *ngFor="let account of listAccounts" [value]="account.id">
                                    <span>{{account.account}} - </span>
                                    <span *ngIf="account.status == 2 && account.activated && account.type == 1">Em teste</span>
                                    <span *ngIf="account.status == 2 && account.activated && account.type == 2">Em monitoramento</span>
                                    <span *ngIf="account.status == 4">Aprovado</span>
                                    <span *ngIf="account.status == 8">Aguardando aprovação</span>
                                    <span *ngIf="account.status == 2 && !account.activated">Aguardando ativação</span>
                                    <span *ngIf="(account.status > 2 && account.status != 4) && account.test_account_operation.status == 3">Reprovada</span>
                                    <span *ngIf="(account.status > 2 && account.status != 4) && account.test_account_operation.status != 3 && account.status != 8">Desativada</span>
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <button class="button-primary" (click)="seach()">
                        <span>Buscar</span>
                    </button>
                </div>
            </div>
        </div>
    </section>
    <section class="row-content withdraw-content" *ngIf="account_operation">
        <div class="card withdraw">
            <div class="logo">
                <img [src]="account_operation.plan.image">
            </div>
            <div class="balance">
                <span class="value">$ {{avaliable.account_balance| number:'1.2-2'}}</span>
                <span class="desc">Saldo total</span>
            </div>
            <div class="values">
                <div class="avaliable">
                    <span class="value">$ {{avaliable.withdraw_available | number:'1.2-2'}}</span>
                    <span class="desc">Disponível para saque</span>
                    <div class="line"></div>
                </div>
                <div class="remaining">
                    <span class="value">$ {{afterWithdraw() | number:'1.2-2'}}</span>
                    <span class="desc">Disponível após saque</span>
                    <div class="line"></div>
                </div>
            </div>
            <span class="obs" *ngIf="true || (avaliable && avaliable.withdraw_description)"><span class="emph">*</span> testes {{avaliable.withdraw_description}}</span>
            <div class="input input-space-min">
                <input class="input-box" type="text" placeholder="Informe o valor para saque" [(ngModel)]="withdraw_value"
                    [class.input-box-error]="withdraw_error" (keyup)="maskMoney()">
                <span class="error-form" *ngIf="withdraw_error">
                    O valor para saque não pode ser menor que zero e nem ultrapassar o limite disponível.
                </span>
            </div>
            <div class="button-center">
                <button class="button-primary" (click)="confirmRequest()">Solicitar</button>
            </div>
        </div>
        <div class="history" *ngIf="listWithDraw.length == 0">
            <span>Nenhuma solicitacão de saque encontrada</span>
        </div>
        <div class="card history" *ngIf="listWithDraw.length != 0">
            <table>
                <thead>
                    <tr>
                        <th>Atendido em</th>
                        <th>Valor solicitado</th>
                        <th>Status</th>
                        <th>Tipo transferência</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of listWithDraw">
                        <td>{{item.completion_date| date: 'dd/MM/yyyy'}}</td>
                        <td>{{item.value | currency: '$'}}</td>
                        <td>
                            <span class="color-warning" *ngIf="item.status == 1">Aguardando aprovação</span>
                            <span class="color-primary-medium" *ngIf="item.status == 2">Aprovado</span>
                            <span class="color-danger" *ngIf="item.status == 3">Negado</span>
                            <span class="color-danger" *ngIf="item.status == 4">Finalizado</span>
                        </td>
                        <td>
                            <span class="color-primary-medium" *ngIf="item.status == 1">PIX</span>
                            <span class="color-info" *ngIf="item.status == 4">TED</span>
                        </td>
                        <td></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </section>
</div>