<app-auth-header page="meta-vision"></app-auth-header>

<div class="content" *ngIf="configuration">
    <div>
        <section>
            <div class="title">Atualização do Insider Investe Real Time</div>
            <div class="over-text">Olá <span class="color-highlight">{{user.name.split(' ')[0]}}</span>, assista o vídeo a seguir e siga o passo a passo para realizar a atualização do Insider Investe Real Time.</div>

            <div class="tutorial">
                <iframe class="video" [src]="configuration.url_tutorial_update_meta_vision"  width="640" height="360" frameborder="0" title="Video player"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowfullscreen *ngIf="this.configuration.url_tutorial_update_meta_vision"></iframe>
    
                <button class="button-primary" (click)="download_file()"><i class="fa-solid fa-download"></i>Baixar Insider Investe</button>
                <button class="button-outline" *ngIf="configuration.url_tutorial_update_meta_vision" [routerLink]="'/ativar-conta-manual'">Atualizar manualmente</button>
            </div>
        </section>
       
       
    </div>
    
</div>

<!-- <app-auth-header></app-auth-header>

<div class="content" *ngIf="configuration">
    <section>
        <div class="title">Baixar e atualizar Insider Investe</div>
        <div class="over-text">Antes de começar, clique no botão abaixo para baixar o instalador atualizado do Meta
            Vision.</div>
        <button class="button-primary" (click)="download_file()"><i class="fa-solid fa-download"></i>Baixar Meta
            Vision</button>
    </section>
    <section>
        <div class="title">Passo a passo da atualização</div>

        <div class="subtitle margin-top">Passo 1</div>
        <div class="over-text">Se o Meta Trader 5 estiver aberto, feche a plataforma e execute o instalador que você
            acabou de realizar o download</div>

        <div class="subtitle margin-top">Passo 2</div>
        <div class="over-text">Após permitir que o Windows execute o instalador, será aberto a janela de instalação.
        </div> <br>
        <div class="over-text"><strong>a. Selecione todas</strong> as linhas que aparecer na janela que irá abrir.</div>
        <div class="over-text"><strong>b. </strong>Clique em <strong>Integrar</strong></div>
        <div class="inline">
            <a href="assets/images/tutorial_install_metavision/step3.PNG" target="_blank">
                <img class="step3" src="assets/images/tutorial_install_metavision/step3.PNG" alt="step 3">
            </a>
        </div>

        <div class="over-text margin-top">Tudo pronto! Você finalizou a atualização do MetaVision, qualquer dúvida, não
            deixe de contatar o nosso suporte através do e-mail <span
                class="color-highlight">suporte@insiderinveste.com.br</span> 
            </div> <br>
    </section>
</div> -->