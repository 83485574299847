import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { UserPasswordCommand } from 'src/app/commands/user-password.command';
import { UserRecoveryPasswordModel } from 'src/app/models/user-recovery-password.model';
import { FirebaseService } from 'src/app/services/firebase/firebase.service';
import { InputFormsService } from 'src/app/services/utils/input-forms.service';

@Component({
  selector: 'app-recover-password',
  templateUrl: './recover-password.component.html',
  styleUrls: ['./recover-password.component.scss']
})
export class RecoverPasswordComponent implements OnInit {

  formValidation: UntypedFormGroup;
  submitted: boolean = false;

  constructor(
    public inputFormService: InputFormsService,
    public formBuilder: UntypedFormBuilder,
    public userPasswordCommand: UserPasswordCommand,
    private firebaseService: FirebaseService,  
  ) { }

  ngOnInit(): void {
    this.firebaseService.viewScreen('recuperar-senha');
    this.formValidation = this.formBuilder.group({
      email: [null, [Validators.required, Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$')]],
    });
  }

  get errorCtr() {
    return this.formValidation.controls;
  }

  focus(type: string, element: string) {
    if (type == 'focus') {
      this.inputFormService.setInputFocus(document, element, this.formValidation, this.submitted);
    } else if (type == 'unfocus') {
      this.inputFormService.unCheckFocus(document, element);
    }
  }

  submit() {
    this.firebaseService.eventClick('solicitar-recuperação', 'recuperar-senha');
    this.submitted = true;


    if (this.formValidation.valid) {

      let recoveryPassword: UserRecoveryPasswordModel = this.formValidation.value as UserRecoveryPasswordModel;
      this.userPasswordCommand.requestRecovery(recoveryPassword);
    }
  }
}
