<app-auth-header page="user-data"></app-auth-header>

<div class="content" *ngIf="!loading">
    <div class="profile-pages">
        <button class="button-transparent active"><i class="fa-solid fa-user"></i><span>Informações pessoais</span></button>
        <button class="button-transparent" [routerLink]="'/meus-cartoes'"><img src="assets/icons/credit.svg"><span>Meus cartões</span></button>
        <button class="button-transparent" [routerLink]="'/minhas-transacoes'"><img src="assets/icons/rules_green.svg"><span>Histórico de transações</span></button>
    </div>
    <div class="center-card">
        <div class="card">
            <div class="card-title">Dados da conta</div>
            <div class="profile-image">
    
                <div class="image">
                    <img [src]="userEdit.profile_image" alt="perfil" *ngIf="userEdit && userEdit.profile_image">
    
                    <div class="not-image" *ngIf="!userEdit || !userEdit.profile_image">
                       <span>{{first_letter_name}}</span>
                    </div>
                    <div class="section-delete" (click)="deleteImage()" *ngIf="userEdit && userEdit.profile_image">
                        <i class="fa-solid fa-trash"></i>
                    </div>
                </div>
    
                <div class="button-edit color-over" (click)="openImageSelection()">
                    <span>Editar</span>
                    <input type="file" id="imageInput" accept="image/*" style="display: none"
                        (change)="handleImageSelection($event)">
                </div>
    
    
            </div>
    
            <form [formGroup]="formValidation">
                <div class="input input-space-min">
                    <input class="input-box input-email" type="text" placeholder="Informe seu email" formControlName="email"
                        (focus)="focus('focus', 'email')" (blur)="focus('unfocus', 'email')">
                </div>
                <div class="input input-space-min">
                    <input class="input-box" type="text" placeholder="Informe seu telefone" formControlName="phone_number"
                        (focus)="focus('focus', 'phone_number')" (blur)="focus('unfocus', 'phone_number')"
                        mask="(00) 0000-0000||(00) 0 0000-0000">
                    <span class="error-form" *ngIf="submitted && errorCtr.phone_number.errors?.required">
                        O campo telefone é obrigatório
                    </span>
                </div>
    
                <div class="input">
                    <input class="input-box" type="text" placeholder="Informe seu nome completo"
                        [class.input-box-error]="submitted && errorCtr.name.errors?.required" formControlName="name"
                        (focus)="focus('focus', 'name')" (blur)="focus('unfocus', 'name')">
                    <span class="error-form" *ngIf="submitted && errorCtr.name.errors?.required">
                        O campo nome é obrigatório
                    </span>
                </div>
            </form>
            <button class="button-secondary" [routerLink]="'/alterar-senha'">Alterar senha</button>
            <button class="button-primary" (click)="submit()">Salvar</button>
        </div>
    </div>
</div>