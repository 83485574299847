import { Component } from '@angular/core';
import { PlanCommand } from 'src/app/commands/plan.command';
import { AssetPlanModel } from 'src/app/models/asset-plan.model';
import { PlanModel } from 'src/app/models/plan.model';

@Component({
  selector: 'app-assets',
  templateUrl: './assets.component.html',
  styleUrls: ['./assets.component.scss']
})
export class AssetsComponent {
  listPlans: PlanModel[] = [];
  plan_id: number;
  plan: PlanModel;
  listAssets: Array<AssetPlanModel[]> = [];
  lastFilter: string = '';
  page: number = 0;

  constructor(
    private planCommand: PlanCommand,
  ){
  }

  ngOnInit() {
    this.planCommand.getPublic().then((result: any) => {
      if(result && result.results)
        this.listPlans = result.results;
    });
  }

  seach() {
    this.plan = null;
    setTimeout(() => {
      this.plan = this.listPlans.find(item => item.id == this.plan_id);
      this.page = 0;
      this.filterAssets('');
    }, 100);
  }

  filterAssets(filterText: string){
    if(this.lastFilter != filterText.toLowerCase()){
      this.lastFilter = filterText.toLowerCase();
      this.page = 0;
    }
    if(filterText.length > 0)
      this.paginate(this.plan.assets.filter(item => item.asset.toLowerCase().includes(filterText.toLowerCase())));
    else
      this.paginate(this.plan.assets);
  }

  paginate(listFilterAssets: AssetPlanModel[] = []){
    this.listAssets = [];
    if(listFilterAssets)
      for(let offset = 0; offset <= listFilterAssets.length; offset+=15)
        this.listAssets.push(listFilterAssets.slice(offset, offset+15));
  }
}
