<app-auth-header page="planos"></app-auth-header>

<div class="content">
    <div class="content-plan">
        <div class="title-plans">Planos</div>

        <div class="description-plan">
            Ao realizar a solicitação de uma nova conta do <strong>mesmo plano</strong> que você já possui sua conta atual será desativada e uma conta nova será disponibilizada pra você.
            <br>
            <br>
            Ao realizar a solicitação de um <strong>novo plano</strong> você poderá manter o teste nas duas contas ao mesmo tempo.
        </div>

        <section class="section-plans">
            <div class="type-plans" *ngIf="hasStockPlans()">
                <button [ngClass]="{'button-primary': typePlan == 'asset', 'button-outline': typePlan != 'asset'}" (click)="typePlan = 'asset'">Índices / Derivativos</button>
                <button [ngClass]="{'button-primary': typePlan == 'stock', 'button-outline': typePlan != 'stock'}" (click)="typePlan = 'stock'">Ações americanas</button>
            </div>
            <div class="title-plans" *ngIf="hasStockPlans()">
                <span *ngIf="typePlan == 'asset'">Índices nacionais, internacionais e derivativos</span>
                <span *ngIf="typePlan == 'stock'">Ações Americanas</span>
            </div>
            <div class="color-over" *ngIf="plans == null">Nenhum plano encontrado</div>

            <div class="card-plans" *ngIf="plans && typePlan == 'asset'">
                <app-card-plan *ngFor="let plan of filterPlan()" [plan]="plan" [atual_plan]="atual_plan?.id"></app-card-plan>
            </div>
            <div class="card-plans" *ngIf="hasStockPlans() && typePlan == 'stock'">
                <app-card-plan *ngFor="let plan of filterPlan()" [plan]="plan" [atual_plan]="atual_plan?.id"></app-card-plan>
            </div>
        </section>
    </div>
</div>