import { Component, Input } from '@angular/core';
import { AccountOperationModel } from 'src/app/models/account-operation.model';
import { DashboardModel } from 'src/app/models/dashboard.model';
import { PerformanceModel } from 'src/app/models/performance.model';

@Component({
  selector: 'app-table-rules-objectives',
  templateUrl: './table-rules-objectives.component.html',
  styleUrls: ['./table-rules-objectives.component.scss']
})
export class TableRulesObjectivesComponent {
  @Input() account_operation: AccountOperationModel;
  @Input() dashboard: DashboardModel;
  @Input() performance: PerformanceModel;

  value_meta: number = 0;
  profit_today: number = 0;
  expanded = [false, false, false, false, false, false, false, false, false, false];
  consistency: { average: number, percent: number, color: string, best_value: number };

  ngOnInit() {
    this.setConsistency();
    this.getAllowableLossDay();
    this.value_meta = Number(this.account_operation.plan.profit_target) + Number(this.account_operation.plan.value_account);
  }

  updateExpanded(position) {
    this.expanded[position] = !this.expanded[position];
    for (let index = 0; index < this.expanded.length; index++) {
      this.expanded[index] = index == position ? this.expanded[index] : false;
    }
  }

  getResultLossAccount() {
    const differenceValue = Number(this.account_operation.account_balance) - Number(this.account_operation.trailing_drawdown);

    if (differenceValue >= this.account_operation.plan.trailing_drawdown) {
      return 0
    }

    return this.account_operation.plan.trailing_drawdown - differenceValue;
  }

  setConsistency() {
    this.consistency = { average: 0, percent: 0, color: "primary", best_value: 0 };

    if (this.dashboard) {
      let average = 0, percent = this.dashboard.consistence, best_value = 0;
      this.consistency = { average, percent, color: percent >= 80 ? "color-primary-medium" : percent >= 50 ? "color-warning" : "color-danger", best_value: best_value }
    }
  }
  
  getAllowableLossDay() {
    let total: number = 0;
    for (let index = 0; index < this.dashboard.day_operations.length; index++) {
      const element = this.dashboard.day_operations[index];
      total += Number(element.value)
    }
    this.profit_today = total;
  }

  sum(a: any, b: any) {
    return Number(a) + Number(b);
  }
}
